import axios from 'axios'

export type RpcPayload =
    | {
          deviceNumber: string
          method: 'User.login'
          params: [{username: string; password: string}]
          token: null
      }
    | {
          method: 'User.loginOauth'
          params: [{code: string; redirect: string}]
          isDynamicDevice: boolean
          deviceNumber: null
          token: null
          timestamp: number
      }

export type LoginResponse = {
    error: null | {message: string}
    result: {
        dynamicDeviceId: string
        message: string
        success: boolean
    }
    token: string
}

const axiosRpcInstance = axios.create({
    baseURL: `${process.env.VUE_APP_PIMCORE_URL as string}/rpc`,
})

export async function sendRpcRequest(payload: RpcPayload): Promise<LoginResponse> {
    const {data} = await axiosRpcInstance.post<LoginResponse>('', payload)
    return data
}
