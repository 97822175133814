import axios from 'axios'

const axiosSuSInstance = axios.create({
    baseURL: 'https://gateway.businesshub.deutschebahn.com/',
    headers: {
        key: process.env.VUE_APP_SECRET_STATION_SERVICES_API,
    },
})

export async function getStationsForName(name: string): Promise<Stada.Station[]> {
    name = decodeURI(name)
    const {data} = await axiosSuSInstance.get<Stada.StationQuery>(`stada/v2/stations/?searchstring=*${name}*&limit=10`)
    return data?.result || []
}

export async function getStationDetails(eva: Dbi.Station['eva']): Promise<Stada.Station | null> {
    // request "/stations/:id" uses the "station code" as id. E.g. 1071 for Berlin Hbf
    const {data} = await axiosSuSInstance.get<Stada.StationQuery>(`stada/v2/stations/?eva=${eva}`)
    return data?.result?.[0] || null
}

export async function get3sCentral(centralId: number): Promise<Stada.SZentrale | null> {
    const {data} = await axiosSuSInstance.get<Stada.SZentraleQuery>(`stada/v2/szentralen/${centralId}`)
    return data?.result?.[0] || null
}

export async function getFacilityStates(stationCode: number): Promise<Fasta.Facility[]> {
    const {data} = await axiosSuSInstance.get<Fasta.Station>(`fasta/v2/stations/${stationCode}`)
    return data?.facilities || []
}
